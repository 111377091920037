import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FieldIcon } from './FieldIcon';

export function WithRefFieldText(props, ref) {
    const {
        icon, team, homeTeam, awayTeam, title
    } = props;
    const isHome = team === 1;
    const wrapperCls = cn('vb-field-text_wrapper text-white', {
        _left: isHome,
        _right: !isHome,
    });

    const txtCls = cn('vb-field-text', {
        'items-end': isHome,
        'items-start': !isHome,
    });

    return (
        <div className={wrapperCls} ref={ref}>
            <FieldIcon team={team} animationName={icon} />
            <div className={txtCls}>
                <p className="vb-action-text">
                    {title}
                </p>
                <p className={cn('vb-team-text', isHome ? 'vb-team-text__left' : 'vb-team-text__right')}>
                    {isHome ? homeTeam : awayTeam}
                </p>
            </div>
        </div>
    );
}

export const FieldText = React.forwardRef(WithRefFieldText);

WithRefFieldText.propTypes = {
    icon: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
