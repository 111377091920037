import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { CountdownAnimation } from 'ui/molecules';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { formattedTimer } from 'features/common/utils';
import { AnimationMap } from 'features/handball/constants';

export function MatchCountdownAnimation() {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { countdown } = store;
    const [translate] = useTranslation();

    const statusText = {
        matchStartsIn: translate('animation.gameStartsIn'),
        matchStartsSoon: translate('animation.gameStartsSoon'),
    };

    const [status, setStatus] = useState(statusText.matchStartsIn);
    const [showCountdown, setShowCountdown] = useState(false);
    const [countdownText, setCountdownText] = useState('');

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);
    useEffect(() => {
        const currentTime = new Date().getTime();
        const countDownTime = countdown.countdown;

        let timerId;

        function startTimer() {
            timerId = setInterval(() => {
                const now = new Date().getTime();
                const timer = Math.floor((countDownTime - now) / 1000);

                if (timer > 0) {
                    const formattedTime = formattedTimer(timer);
                    setCountdownText(formattedTime);
                } else {
                    clearInterval(timerId);
                    setShowCountdown(false);
                    setStatus(statusText.matchStartsSoon);
                }
            }, 1000);
        }

        if (countdown.countdown > 0) {
            const timer = Math.floor((countDownTime - currentTime) / 1000);
            const formattedTime = formattedTimer(timer);
            setCountdownText(formattedTime);
            setShowCountdown(true);
            setStatus(statusText.matchStartsIn);
            startTimer();
        } else {
            setShowCountdown(false);
            setStatus(statusText.matchStartsSoon);
        }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const statusCls = cx('text-2xl sm:text-3xl handball-text-secondary uppercase', {
        'mb-auto': showCountdown,
    });

    const countdownAnimation = (
        <div className="animation__countdown" ref={nodeRef}>
            <div className="handball-full_rectangle flex justify-center items-center flex-col">
                {showCountdown && (
                    <p className="text-3xl mt-10% sm:text-4xl handball-te">
                        {countdownText}
                    </p>
                )}
                <p className={statusCls}>
                    {status}
                </p>
            </div>
        </div>
    );

    return (
        <CountdownAnimation
            showCountdown={showCountdown}
            defaultComponent={countdownAnimation}
            status={status}
            countdownText={countdownText}
        />
    );
}
MatchCountdownAnimation.ANIMATION_NAME = AnimationMap.MatchCountdown;
