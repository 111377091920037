import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/beachVolleyball/constants';

export function MatchEndAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();

    return (
        <div className="h-full" ref={nodeRef}>
            <div className="beach-volleyball-full_rectangle">
                <p className="animation__field--text-action">
                    {translate('animation.matchEnd')}
                </p>
            </div>
        </div>
    );
}

MatchEndAnimation.ANIMATION_NAME = AnimationMap.MatchEnd;
