import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/badminton/constants';

export function ChallengeAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    return (
        <div className="h-full" ref={nodeRef}>
            <div className="badminton-full_rectangle">
                <p className="animation__field--text-action">
                    {translate('animation.challenge')}
                </p>
            </div>
        </div>
    );
}

ChallengeAnimation.ANIMATION_NAME = AnimationMap.Challenge;
