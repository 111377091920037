import React, { Suspense, useCallback, useState, useTransition } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LoaderContextProvider } from 'context';

import { Loader } from 'ui';
import { routes } from './routes';

import './assets/scss/common/index.scss';

export function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [, startTransition] = useTransition();

    const setLoading = useCallback(
        (loading) => {
            startTransition(() => {
                setIsLoading(loading);
            });
        },
        [startTransition]
    );

    return (
        <>
            {isLoading && <Loader className="app-loader" />}
            <LoaderContextProvider setLoading={setLoading}>
                <Suspense>
                    <Router>
                        <Switch>
                            {routes.map((route) => (
                                <Route
                                    key={route.name}
                                    path={route.path}
                                    component={route.component}
                                />
                            ))}
                        </Switch>
                    </Router>
                </Suspense>
            </LoaderContextProvider>
        </>
    );
}
