import { useContext, useEffect, useState } from 'react';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { FreezedEmitter } from 'features/common/services/emitter.service';

export const useFieldAnimatorConfig = ({ isMobile }) => {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;

    const [animationData, setAnimationData] = useState({
        eventData: store.currentAnimation.eventData,
        currentAnimation: store.currentAnimation.componentName,
    });
    const [calculatedWidth, setCalculatedWidth] = useState(null);

    useEffect(() => {
        FreezedEmitter.on('newAnimation', (data) => {
            const { eventData: evtData, componentName } = data;
            setAnimationData({
                eventData: evtData,
                currentAnimation: componentName,
            });
        });
        return () => {
            FreezedEmitter.emit('resetVirgin');
            FreezedEmitter.off('newAnimation');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getMaxWidth() {
        const horizontalOffset = isMobile ? 10 : 20;
        return window.innerWidth - 2 * horizontalOffset;
    }

    function fieldWidth() {
        const maxWidth = getMaxWidth();
        return calculatedWidth ? Math.min(maxWidth, calculatedWidth) : null;
    }

    return {
        store,
        animationData,
        setAnimationData,
        calculatedWidth,
        setCalculatedWidth,
        fieldWidth,
    };
};
