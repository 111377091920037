import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/beachVolleyball/constants';

export function PlayersWarmupAnimation() {
    const [translate] = useTranslation();

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div className="h-full" ref={nodeRef}>
            <div className="beach-volleyball-full_rectangle">
                <p className="animation__field--text-action">
                    {translate('animation.playersWarmup')}
                </p>
            </div>
        </div>
    );
}

PlayersWarmupAnimation.ANIMATION_NAME = AnimationMap.PlayersWarmup;
