import React, { createContext } from 'react';

export const LoaderContext = createContext();

export function LoaderContextProvider ({ children, setLoading }){
    return (
        <LoaderContext.Provider value={{ setLoading }}>
            {children}
        </LoaderContext.Provider>
    );
}
