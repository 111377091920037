import React from 'react';
import cn from 'classnames';

export function DefaultLoader({className}) {
    return (
        <div className={cn('loader', className)}>
            <span className="loader-spinner" />
        </div>
    );
}
