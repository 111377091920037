import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTargetName } from 'hooks';
import { FieldIcon } from './FieldIcon';

export function WithRefFieldText(props, ref) {
    const {
        icon, team, homeTeam, awayTeam, title
    } = props;

    const isHome = team === 1;

    const { conditions } = useTargetName();

    return (
        <div className={cn(
            'tt-field-text',
            isHome ? 'tt-field-text--left' : 'tt-field-text--right'
        )} ref={ref}>
            {(!isHome || !conditions.isVivi) && <FieldIcon team={team} animationName={icon} />}
            <div className={cn(
                'tt-field-text__items',
                isHome ? 'tt-field-text__items--end' : 'tt-field-text__items--start'
            )}>
                <p className="tt-field-text__action">
                    {title}
                </p>
                <p className="tt-field-text__team">
                    {isHome ? homeTeam : awayTeam}
                </p>
            </div>
            {(isHome && conditions.isVivi) && <FieldIcon team={team} animationName={icon} />}
        </div>
    );
}

export const FieldText = React.forwardRef(WithRefFieldText);

WithRefFieldText.propTypes = {
    icon: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
