import React from 'react';

import {useTargetName} from 'hooks';

export const CountdownAnimation = ({ showCountdown, status, countdownText, defaultComponent }) => {
    const {conditions: {isVivi}} = useTargetName();

    if (!isVivi) {
        return defaultComponent;
    }

     return (
         <div className="animation__countdown">
             <div className="animation__full_rectangle">
                 <p className="animation__field--text-action">
                     {status}
                 </p>
                 {showCountdown && (
                     <p className="animation__field--text-action">
                         {countdownText}
                     </p>
                 )}
             </div>
         </div>
     );
};
